.experience {
  width: 100vw;
  height: 100vh;

  .experience-info {
    margin: 40px 10vw 30px;
    text-align: left;
    color: #f97a87;
    opacity: .9;

    .experience-title {
      font-size: 20px;
    }

    .experience-date {
      margin-top: 5px;
      font-size: 18px;
    }

    .experience-description {
      margin-top: 5px;
      font-size: 16px;
    }
  }

  .experience-image-box {
    position: relative;
    margin: 0 10vw;

    .btn-right-arrow,
    .btn-left-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      animation: 3s emerge-gradually infinite;
    }

    .btn-right-arrow {
      right: 5px;
    }

    .btn-left-arrow {
      left: 5px;
    }

    .experience-images {
      position: relative;
      display: flex;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      width: 80vw;
      height: auto;
      max-height: calc(100vh - 214px);
      user-select: none;

      .experience-image-item {
        margin-right: 10px;
        max-width: 80vw;
        flex-shrink: 0;
        max-height: calc(100vh - 230px);
        font-size: 0;

        &:last-child {
          margin-right: 0;
        }

        .experience-image-thumb,
        .experience-image {
          display: block;
          max-width: 80vw;
          height: calc(100vh - 230px);
          object-fit: contain;
        }

        .experience-image {
          transform: translateY(-100%);
        }
      }
    }
  }
}
