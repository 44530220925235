.heart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .heart-content {
    width: 200px;
    height: 200px;
    background-color: pink;
    user-select: none;
    transform: rotate(-45deg);

    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 100px;
      width: 200px;
      height: 200px;
      background-color: pink;
      border-radius: 50%;
      z-index: 1;
      content: "";
    }

    &::before {
      top: -100px;
      left: 0;
    }
  }

  &.clicked {
    .heart-content {
      animation: jelly .5s;
    }
  }

  .heart-inner {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
}

@keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1) rotate(-45deg);  //rotate保持爱心方向
  }

  25%,
  75% {
    transform: scale(.9, 1.1) rotate(-45deg);
  }

  50% {
    transform: scale(1.1, .9) rotate(-45deg);
  }
}
