.photos {
  width: 100vw;
  height: 100vh;

  .album {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
