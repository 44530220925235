.home {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  min-height: 100vh;
  background: #ffe;

  .home-header {
    position: relative;
    height: 100vh;
  }

  .say-hey {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: bold;
    color: pink;
    animation: 5s fade-out;

    .hey-text {
      margin: 5px;
    }

    .nick {
      margin: 5px;
    }
  }

  .heart-box {
    animation: 4s fade-in;
  }

  .timer-box {
    position: absolute;
    top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .timer-content {
      font-size: 20px;
      font-weight: bold;
      color: white;
    }
  }

  .btn-down-arrow {
    position: absolute;
    bottom: 30px;
    left: 50vw;
    transform: translateX(-50%);
    color: pink;
    animation: 3s emerge-gradually infinite;
  }

  .experience-item {
    position: relative;
    height: 100vh;
    border-top: 5px dashed #FFC0CB;
  }

  .unfinished {
    margin: 30vh 0;
    font-size: 20px;
    text-align: center;
    color: #f97a87;
    opacity: .9;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes emerge-gradually {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
